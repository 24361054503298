import { Global, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { defaultTheme, mergeTheme, ThemeProvider as EvergreenThemeProvider } from 'evergreen-ui';
import { type PageProps } from 'gatsby';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';

import { globalStyles } from './assets/styles/global.styles';
import { boxShadows, breakpoints, spacing } from './assets/styles/tokens';
import AtomsProvider from './components/misc/atomsProvider';
import { atomsStore } from './stores/atoms.store';

const theme = mergeTheme(defaultTheme, {
  components: {
    Button: {
      baseStyle: {}
    }
  }
});

export interface RootProps extends PropsWithChildren {
  pageProps: PageProps<any, any>;
}

const Root: FC<RootProps> = ({ children, pageProps }) => {
  return (
    <EvergreenThemeProvider value={theme}>
      <EmotionThemeProvider theme={{ ...theme, breakpoints, spacing, boxShadows }}>
        <Global styles={globalStyles} />
        <AtomsProvider pageProps={pageProps} store={atomsStore}>
          {children}
        </AtomsProvider>
      </EmotionThemeProvider>
    </EvergreenThemeProvider>
  );
};

export default Root;
