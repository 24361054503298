export const BuilderTabEnum = {
  Basic: 'basic',
  ComponentList: 'componentList',
  ComponentEdit: 'componentEdit',
  Contact: 'contact',
  CoverLetter: 'coverLetter',
  Layout: 'layout',
  Settings: 'settings'
};

export function isBuilderTabEnum(val?: string | null): val is BuilderTabEnum {
  if (val && Object.values(BuilderTabEnum).includes(val)) {
    return true;
  } else {
    return false;
  }
}

export type BuilderTabEnum = (typeof BuilderTabEnum)[keyof typeof BuilderTabEnum];
