import { type PageProps } from 'gatsby';
import { type createStore, Provider } from 'jotai';
import React, { type FC, type PropsWithChildren, useEffect } from 'react';

import { cvAtom, initialValue as cvInitialValue } from '../../atoms/cv.atom';
import { cvImageAtom } from '../../atoms/cvImage.atom';
import { cvMetaAtom, initialValue as cvMetaInitialValue } from '../../atoms/cvMeta.atom';
import { initialValue as modalsInitialValue, modalsAtom } from '../../atoms/modals.atom';
import { StorageKeyEnum } from '../../enums';
import { useRootTranslation } from '../../hooks/useRootTranslation';
import { useStoredValue } from '../../hooks/useStoredValue';
import { atomsStore } from '../../stores/atoms.store';
import { type Cv } from '../../types/cv.types';
import { type CvImage } from '../../types/cvImage.types';
import { getExampleCv, getExampleCvImage } from '../../utils/exampleData';

export interface AtomsProviderProps extends PropsWithChildren {
  pageProps?: PageProps<any, any>;
  store: ReturnType<typeof createStore>;
}

const AtomsProvider: FC<AtomsProviderProps> = ({ children, pageProps, store }) => {
  const { lang, t } = useRootTranslation(pageProps);
  const { storeValue: storeCv } = useStoredValue<Cv>({
    onLoad: data => store.set(cvAtom, data),
    onNoValue: () => store.set(cvAtom, getExampleCv(cvInitialValue, t, lang)),
    version: '0.5.0',
    storageKey: StorageKeyEnum.Cv,
    loadTime: 'useLayoutEffect',
    storageType: 'local'
  });

  const { storeValue: storeCvImage } = useStoredValue<CvImage>({
    onLoad: data => store.set(cvImageAtom, data),
    onNoValue: () => store.set(cvImageAtom, getExampleCvImage()),
    version: '0.5.0',
    storageKey: StorageKeyEnum.CvImage,
    loadTime: 'useLayoutEffect',
    storageType: 'local'
  });

  useEffect(() => {
    store.set(cvMetaAtom, cvMetaInitialValue);
    store.set(modalsAtom, modalsInitialValue);
    store.sub(cvAtom, () => storeCv(store.get(cvAtom)));
    store.sub(cvImageAtom, () => storeCvImage(store.get(cvImageAtom)));
  }, []);

  return <Provider store={atomsStore}>{children}</Provider>;
};

export default AtomsProvider;
