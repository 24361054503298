import { type FontData } from '../../types/font.types';
import carattereRegular from './Carattere-Regular.ttf';
import playfairBold from './Playfair_9pt-Bold.ttf';
import playfairRegular from './Playfair_9pt-Regular.ttf';
import robotoBold from './Roboto-Bold.ttf';
import robotoItalic from './Roboto-Italic.ttf';
import robotoRegular from './Roboto-Regular.ttf';

function getFontFace(fontData: FontData) {
  return `
    @font-face {
      font-family: '${fontData.family}';
      font-style: ${fontData.style};
      font-weight: ${fontData.weight};
      src: url('${fontData.url}') format('${fontData.format}');
    }
  `;
}

export const allFonts = {
  playfairRegular: {
    family: 'Playfair',
    style: 'normal',
    weight: 'normal',
    format: 'truetype',
    url: playfairRegular,
    get fontFace() {
      return getFontFace(this);
    }
  },
  playfairBold: {
    family: 'Playfair',
    style: 'normal',
    weight: 'bold',
    format: 'truetype',
    url: playfairBold,
    get fontFace() {
      return getFontFace(this);
    }
  },
  carattereRegular: {
    family: 'Carattere',
    style: 'normal',
    weight: 'normal',
    url: carattereRegular,
    format: 'truetype',
    get fontFace() {
      return getFontFace(this);
    }
  },
  robotoBold: {
    family: 'Roboto',
    style: 'normal',
    weight: 'bold',
    url: robotoBold,
    format: 'truetype',
    get fontFace() {
      return getFontFace(this);
    }
  },
  robotoItalic: {
    family: 'Roboto',
    style: 'italic',
    weight: 'normal',
    url: robotoItalic,
    format: 'truetype',
    get fontFace() {
      return getFontFace(this);
    }
  },
  robotoRegular: {
    family: 'Roboto',
    style: 'normal',
    weight: 'normal',
    url: robotoRegular,
    format: 'truetype',
    get fontFace() {
      return getFontFace(this);
    }
  }
} satisfies Record<string, FontData>;
