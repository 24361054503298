import { useEffect, useRef } from 'react';

import { type StorageKeyEnum } from '../enums';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

export interface StoredValueProps<FieldValue = Record<string, any>> {
  onLoad?: (value: FieldValue) => void;
  onNoValue?: () => void;
  storageKey: StorageKeyEnum;
  storageType?: 'local' | 'session';
  loadTime?: 'useLayoutEffect' | 'useEffect';
  version: string;
}

export interface StoredValueReturn<FieldValue = Record<string, any>> {
  restoreValue: () => FieldValue | undefined;
  storeValue: (values?: FieldValue) => void;
}

/**
 * Returns a payload for storing values in storage.
 **/
export function useStoredValue<FieldValue = Record<string, any>>({
  loadTime = 'useLayoutEffect',
  onLoad,
  onNoValue,
  storageKey,
  storageType = 'local',
  version
}: StoredValueProps<FieldValue>): StoredValueReturn<FieldValue> {
  const storage = ((): Storage | undefined => {
    if (typeof window === 'undefined') return undefined;
    if (storageType === 'local') return window.localStorage;
    if (storageType === 'session') return window.sessionStorage;
  })();

  const loadedRef = useRef<boolean>(false);

  const storeValue: StoredValueReturn<FieldValue>['storeValue'] = value => {
    if (typeof storage === 'undefined' || !loadedRef.current) return;
    if (value) {
      const stringValue = JSON.stringify({ ...value, version });
      storage.setItem(storageKey, stringValue);
    } else {
      storage.removeItem(storageKey);
    }
  };

  const restoreValue: StoredValueReturn<FieldValue>['restoreValue'] = () => {
    if (typeof storage === 'undefined') return;
    const stringValue = storage.getItem(storageKey);
    if (!stringValue) return undefined;
    const parsedValue = JSON.parse(stringValue);
    if (parsedValue?.version === version) {
      delete parsedValue.version;
      return parsedValue;
    } else {
      storage.removeItem(storageKey);
      return undefined;
    }
  };

  (loadTime === 'useEffect' ? useEffect : useIsomorphicLayoutEffect)(() => {
    const value = restoreValue();
    if (value) {
      onLoad?.(value);
    } else {
      onNoValue?.();
    }
    loadedRef.current = true;
  }, []);

  return {
    storeValue,
    restoreValue
  };
}
