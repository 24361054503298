/**
 * Components used as building blocks in all cvs
 */
export enum ComponentTypeEnum {
  Text = 'text',
  Bullets = 'bullets',
  Timeline = 'timeline',
  Graphs = 'graphs',
  Categories = 'categories'
}
