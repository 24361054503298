export enum TemplateEnum {
  Argentia = 'argentia',
  Gliese = 'gliese',
  Celestria = 'celestria',
  Tau = 'tau'
}

export function isTemplateEnum(val: any): val is TemplateEnum {
  if (Object.values(TemplateEnum).includes(val)) {
    return true;
  } else {
    console.warn(`Type check failed. Value is not of type TemplateEnum`, val);
    return false;
  }
}
