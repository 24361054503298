import { atom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { focusAtom } from 'jotai-optics';

import { LangEnum, PageTypeEnum, TemplateEnum } from '../enums';
import { type Cv } from '../types/cv.types';
import { getRandomKey } from '../utils/getRandomKey';

export const initialValue: Cv = {
  settings: {
    lang: LangEnum.En,
    template: TemplateEnum.Gliese,
    activeCoverLetter: false,
    apDisplayContact: true,
    apDisplayImage: true,
    apDisplayName: true,
    apDisplayProfession: true,
    clpDisplayContact: true,
    clpDisplayImage: true,
    clpDisplayName: true,
    clpDisplayProfession: true
  },
  allPages: {
    [PageTypeEnum.One]: [
      {
        id: getRandomKey(),
        full: [],
        left: [],
        right: []
      }
    ],
    [PageTypeEnum.Two]: [
      {
        id: getRandomKey(),
        full: [],
        left: [],
        right: []
      }
    ],
    [PageTypeEnum.T]: [
      {
        id: getRandomKey(),
        full: [],
        left: [],
        right: []
      }
    ]
  },
  coverLetter: {
    city: '',
    country: '',
    street: '',
    zipCode: '',
    company: '',
    date: true,
    email: '',
    phone: '',
    professionTitle: '',
    recipientName: '',
    text: ''
  },
  basics: {
    postNominalLetters: '',
    preNominalLetters: '',
    firstName: '',
    lastName: '',
    middleName: '',
    profession: ''
  },
  components: [],
  contact: {
    city: '',
    country: '',
    email: '',
    facebook: '',
    gitHub: '',
    instagram: '',
    linkedIn: '',
    phone: '',
    reddit: '',
    snapchat: '',
    street: '',
    tikTok: '',
    web: '',
    x: '',
    youTube: '',
    zipCode: ''
  }
};

export const cvAtom = atom<Cv>(initialValue);

/**
 * basics
 */

export const preNominalLettersAtom = focusAtom(cvAtom, o =>
  o.prop('basics').prop('preNominalLetters')
);
export const firstNameAtom = focusAtom(cvAtom, o => o.prop('basics').prop('firstName'));
export const middleNameAtom = focusAtom(cvAtom, o => o.prop('basics').prop('middleName'));
export const lastNameAtom = focusAtom(cvAtom, o => o.prop('basics').prop('lastName'));
export const postNominalLettersAtom = focusAtom(cvAtom, o =>
  o.prop('basics').prop('postNominalLetters')
);
export const professionAtom = focusAtom(cvAtom, o => o.prop('basics').prop('profession'));
// utils
export const renderNameAtom = selectAtom(cvAtom, cv =>
  [
    cv.basics.preNominalLetters,
    cv.basics.firstName,
    cv.basics.middleName,
    cv.basics.lastName,
    cv.basics.postNominalLetters
  ].some(item => item?.trim())
);
// utils
export const renderProfessionAtom = selectAtom(cvAtom, cv => !!cv.basics.profession.trim());

/**
 * contact
 */

export const contactAtom = focusAtom(cvAtom, o => o.prop('contact'));
export const emailAtom = focusAtom(cvAtom, o => o.prop('contact').prop('email'));
export const phoneAtom = focusAtom(cvAtom, o => o.prop('contact').prop('phone'));
export const webAtom = focusAtom(cvAtom, o => o.prop('contact').prop('web'));
// address
export const streetAtom = focusAtom(cvAtom, o => o.prop('contact').prop('street'));
export const cityAtom = focusAtom(cvAtom, o => o.prop('contact').prop('city'));
export const zipCodeAtom = focusAtom(cvAtom, o => o.prop('contact').prop('zipCode'));
export const countryAtom = focusAtom(cvAtom, o => o.prop('contact').prop('country'));
// social
export const facebookAtom = focusAtom(cvAtom, o => o.prop('contact').prop('facebook'));
export const gitHubAtom = focusAtom(cvAtom, o => o.prop('contact').prop('gitHub'));
export const instagramAtom = focusAtom(cvAtom, o => o.prop('contact').prop('instagram'));
export const linkedInAtom = focusAtom(cvAtom, o => o.prop('contact').prop('linkedIn'));
export const redditAtom = focusAtom(cvAtom, o => o.prop('contact').prop('reddit'));
export const snapchatAtom = focusAtom(cvAtom, o => o.prop('contact').prop('snapchat'));
export const tikTokAtom = focusAtom(cvAtom, o => o.prop('contact').prop('tikTok'));
export const xAtom = focusAtom(cvAtom, o => o.prop('contact').prop('x'));
export const youTubeAtom = focusAtom(cvAtom, o => o.prop('contact').prop('youTube'));
// utils
export const renderContactAtom = selectAtom(contactAtom, contact =>
  Object.values(contact).some(item => item?.trim())
);

/**
 * allPages
 */

export const allPagesAtom = focusAtom(cvAtom, o => o.prop('allPages'));

/**
 * settings
 */

export const langAtom = focusAtom(cvAtom, o => o.prop('settings').prop('lang'));
export const templateAtom = focusAtom(cvAtom, o => o.prop('settings').prop('template'));
export const activeCoverLetterAtom = focusAtom(cvAtom, o =>
  o.prop('settings').prop('activeCoverLetter')
);
export const apDisplayContactAtom = focusAtom(cvAtom, o =>
  o.prop('settings').prop('apDisplayContact')
);
export const apDisplayNameAtom = focusAtom(cvAtom, o => o.prop('settings').prop('apDisplayName'));
export const apDisplayImageAtom = focusAtom(cvAtom, o => o.prop('settings').prop('apDisplayImage'));
export const apDisplayProfessionAtom = focusAtom(cvAtom, o =>
  o.prop('settings').prop('apDisplayProfession')
);
export const clpDisplayContactAtom = focusAtom(cvAtom, o =>
  o.prop('settings').prop('clpDisplayContact')
);
export const clpDisplayNameAtom = focusAtom(cvAtom, o => o.prop('settings').prop('clpDisplayName'));
export const clpDisplayImageAtom = focusAtom(cvAtom, o =>
  o.prop('settings').prop('clpDisplayImage')
);
export const clpDisplayProfessionAtom = focusAtom(cvAtom, o =>
  o.prop('settings').prop('clpDisplayProfession')
);

/**
 * components
 */

export const componentsAtom = focusAtom(cvAtom, o => o.prop('components'));

/**
 * cover letter
 */

export const clTextAtom = focusAtom(cvAtom, o => o.prop('coverLetter').prop('text'));
export const clDateAtom = focusAtom(cvAtom, o => o.prop('coverLetter').prop('date'));
export const toProfessionTitleAtom = focusAtom(cvAtom, o =>
  o.prop('coverLetter').prop('professionTitle')
);
export const toRecipientNameAtom = focusAtom(cvAtom, o =>
  o.prop('coverLetter').prop('recipientName')
);
export const toCompanyAtom = focusAtom(cvAtom, o => o.prop('coverLetter').prop('company'));
export const toStreetAtom = focusAtom(cvAtom, o => o.prop('coverLetter').prop('street'));
export const toCityAtom = focusAtom(cvAtom, o => o.prop('coverLetter').prop('city'));
export const toCountryAtom = focusAtom(cvAtom, o => o.prop('coverLetter').prop('country'));
export const toZipCodeAtom = focusAtom(cvAtom, o => o.prop('coverLetter').prop('zipCode'));
export const toEmailAtom = focusAtom(cvAtom, o => o.prop('coverLetter').prop('email'));
export const toPhoneAtom = focusAtom(cvAtom, o => o.prop('coverLetter').prop('phone'));
// utils
export const renderToAddressAtom = selectAtom(cvAtom, ({ coverLetter }) =>
  [coverLetter.city, coverLetter.country, coverLetter.street, coverLetter.zipCode].some(item =>
    item?.trim()
  )
);
export const renderToAtom = selectAtom(cvAtom, ({ coverLetter }) =>
  [
    coverLetter.company,
    coverLetter.email,
    coverLetter.phone,
    coverLetter.professionTitle,
    coverLetter.recipientName
  ].some(item => item?.trim())
);
