import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';

import { type CvMeta } from '../types/cvMeta.types';

export const initialValue: CvMeta = {
  isGenerating: false
};

export const cvMetaAtom = atom(initialValue);

export const isGeneratingAtom = focusAtom(cvMetaAtom, o => o.prop('isGenerating'));
