import { atom } from 'jotai';

import { type Modals } from '../types/modals.types';

export const initialValue: Modals = {
  addExistingComponent: null,
  createComponent: null,
  deleteComponent: null,
  deletePage: null,
  moveComponentRef: null,
  removeComponentRef: null,
  renameComponent: null,
  deleteTimelineItem: null,
  deleteBulletPoint: null,
  useComponent: null,
  deleteGraph: null,
  deleteCategory: null,
  clearData: null,
  exampleData: null
};

export const modalsAtom = atom(initialValue);
