import { css } from '@emotion/react';

import { SortableClassEnum } from '../../enums';

export const sortable = css`
  .${SortableClassEnum.Ghost} {
    opacity: 0 !important;
    @media (hover: none) {
      opacity: 0.3 !important;
    }
  }
  .${SortableClassEnum.Drag} {
    opacity: 1 !important;
    * {
      opacity: 1 !important;
    }
  }

  // https://github.com/SortableJS/Sortable/issues/1886#issuecomment-678637056
  .sortable-fallback {
    opacity: 1 !important;
  }
`;
