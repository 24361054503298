import { css, type Theme } from '@emotion/react';

import { fonts } from './fonts.styles';
import { sortable } from './sortable.styles';

export const globalStyles = (t: Theme) => css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .colorPrimary {
    color: ${t.colors.selected};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition:
      background-color 0s 600000s,
      color 0s 600000s !important;
  }

  ${fonts}
  ${sortable}
`;
