import { atom } from 'jotai';
import { selectAtom } from 'jotai/utils';

import { type CvImage } from '../types/cvImage.types';

export const initialValue: CvImage = {
  src: null
};

export const cvImageAtom = atom<CvImage>(initialValue);

// utils
export const renderImageAtom = selectAtom(cvImageAtom, cvImage => !!cvImage.src);
