export const LangEnum = {
  Cs: 'cs',
  En: 'en'
};

export type LangEnum = (typeof LangEnum)[keyof typeof LangEnum];

export function isLangEnum(val: any): val is LangEnum {
  if (Object.values(LangEnum).includes(val)) {
    return true;
  } else {
    console.warn(`Type check failed. Value is not of type LangEnum`, val);
    return false;
  }
}
