import { type HeadProps, type PageProps } from 'gatsby';

import { isLangEnum, LangEnum } from '../enums';

type LocaleData = string | { [key: string]: LocaleData };

type Locales = {
  ns: string;
  language: LangEnum;
  data: LocaleData;
}[];

export interface RootTranslationReturn {
  lang?: LangEnum;
  t: (translationKey: string) => string;
}

/**
 * Workaround hook because gatsby-plugin-react-i18next is piece of shit
 * https://gitHub.com/microapps/gatsby-plugin-react-i18next/issues/179
 */
export function useRootTranslation(
  props?: HeadProps<any, any> | PageProps<any, any>
): RootTranslationReturn {
  if (!props) {
    return {
      lang: LangEnum.En,
      t: translationKey => translationKey
    };
  }
  let lang: LangEnum | undefined;
  if (isLangEnum(props.pageContext?.language)) {
    lang = props.pageContext?.language;
  }
  const locales: Locales | undefined = props.data?.locales?.edges?.map(
    (item: { node: { ns: string; data: string; language: LangEnum } }) =>
      ({ ns: item.node.ns, language: item.node.language, data: JSON.parse(item.node.data) }) || []
  );
  const t: RootTranslationReturn['t'] = translationKey => {
    const translationKeyArr = translationKey.split('.');
    const localeData = locales?.find(
      item => item.language === lang && item.ns === translationKeyArr[0]
    )?.data;
    if (!localeData) return translationKey;
    const translation = translationKeyArr.reduce((object, key) => {
      if (typeof object === 'object') {
        return object?.[key];
      } else {
        return object;
      }
    }, localeData);
    if (typeof translation === 'string') {
      return translation;
    } else {
      return translationKey;
    }
  };
  return { lang, t };
}
